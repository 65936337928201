<template>
  <div class="emoji-container">
    <div class="emoji-icons">
      <EmojiItem v-for="(icon, index) in icons" :key="index" :svgPath="icon.svgPath" :count="icon.count"
        :hoverColor="icon.hoverColor" :clickColor="icon.clickColor" :voted="icon.voted" :emojiType="icon.emojiType"
        @toggleCount="toggleCount(index)" />
    </div>
  </div>
</template>

<script>
import EmojiItem from './EmojiItem.vue';
import { db } from '@/firebase';
import { doc, updateDoc, increment, onSnapshot } from 'firebase/firestore';
import { serverTimestamp } from "firebase/firestore";

export default {
  components: {
    EmojiItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    postId: {
      type: String,
      required: true,
      default: "1",
    },
  },
  data() {
    return {
      expanded: false,
      icons: [
        {
          svgPath: "m19.3,18.24l-2.78-2.76c2.33-2.91,1.87-7.16-1.04-9.49-2.91-2.33-7.16-1.87-9.49,1.04-2.33,2.91-1.87,7.16,1.04,9.49,2.47,1.98,5.98,1.98,8.45,0l2.76,2.76c.29.29.77.3,1.06,0,0,0,0,0,0,0,.27-.29.26-.75,0-1.04Zm-8.03-1.72c-2.9,0-5.25-2.35-5.25-5.25s2.35-5.25,5.25-5.25,5.25,2.35,5.25,5.25-2.35,5.25-5.25,5.25Z",
          count: 0,
          voted: false,
          hoverColor: 'rgb(29, 155, 240, 0.2)',
          clickColor: 'rgb(29, 155, 240, 1)',
          emojiType: 'scope',
        },
        {
          svgPath: "M11.34 18 L12 6 L10 6 L10.66 18 Z M11 19.5c-1,0-1.5,0.7-1.5,1.5s0.5,1.5,1.5,1.5,1.5-0.7,1.5-1.5c0-0.8-0.5-1.5-1.5-1.5Z",
          count: 0,
          voted: false,
          hoverColor: 'rgba(0, 186, 124, 0.2)',
          clickColor: 'rgba(0, 186, 124, 1)',
          emojiType: 'surprise',
        },
        {
          svgPath: "M 16.697 5.5 c -1.222 -0.06 -2.679 0.51 -3.89 2.16 l -0.805 1.09 l -0.806 -1.09 C 9.984 6.01 8.526 5.44 7.304 5.5 c -1.243 0.07 -2.349 0.78 -2.91 1.91 c -0.552 1.12 -0.633 2.78 0.479 4.82 c 1.074 1.97 3.257 4.27 7.129 6.61 c 3.87 -2.34 6.052 -4.64 7.126 -6.61 c 1.111 -2.04 1.03 -3.7 0.477 -4.82 c -0.561 -1.13 -1.666 -1.84 -2.908 -1.91 z z",
          count: 0,
          voted: false,
          hoverColor: 'rgba(224, 36, 126, 0.2)',
          clickColor: 'rgba(224, 36, 126, 1)',
          emojiType: 'heart',
        },
        {
          svgPath: "M 12 8.75 a 3.25 3.25 90 1 0 0 6.5 a 3.25 3.25 90 0 0 0 -6.5 z z M 3.1249 12 a 17.0729 17.0729 90 0 1 2.158 -2.6559 C 6.956 7.6684 9.244 6.15 12 6.15 c 2.756 0 5.0427 1.5184 6.7184 3.1941 A 17.0729 17.0729 90 0 1 20.8764 12 c -0.0754 0.1131 -0.1586 0.2379 -0.2535 0.3744 c -0.4355 0.624 -1.079 1.456 -1.9045 2.2815 C 17.0427 16.3316 14.7547 17.85 12 17.85 c -2.756 0 -5.0427 -1.5184 -6.7184 -3.1941 A 17.0742 17.0742 90 0 1 3.1236 12 z",
          count: 0,
          voted: false,
          hoverColor: 'rgb(29, 155, 240, 0.2)',
          clickColor: 'rgb(29, 155, 240, 1)',
          emojiType: 'seen',
        },
      ],
    };
  },
  mounted() {
    this.setupListener();
  },
  methods: {
    setupListener() {
      const postRef = doc(db, 'posts', this.postId);
      onSnapshot(postRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          this.updateEmojiCounts(data.postReactions);
        }
      });
    },
    updateEmojiCounts(reactions) {
      this.icons.forEach(icon => {
        icon.count = reactions[icon.emojiType] || 0;
      });
    },
    async toggleCount(index) {
      const icon = this.icons[index];
      const postRef = doc(db, 'posts', this.postId);
      try {
        if (icon.emojiType === 'seen') {
          return
        }
        else if (icon.emojiType === 'scope') {
          console.log(`🔍 Click detected on scope. Expanded before toggle: ${this.expanded}`);

          this.$forceUpdate();
          console.log(`🔄 Expanded after toggle: ${this.expanded}`);
          this.expanded = !this.expanded;
          this.$emit('toggleExpanded', this.expanded);

          if (this.expanded) {
            console.log("✅ Firestore update about to be triggered!");

            await updateDoc(postRef, { [`postReactions.${icon.emojiType}`]: increment(1), updatedAt: serverTimestamp() });
            console.log("✅ Firestore update DONE!");
          }

        } else {
          await updateDoc(postRef, { [`postReactions.${icon.emojiType}`]: increment(icon.voted ? -1 : 1), updatedAt: serverTimestamp() });
          icon.voted = !icon.voted;
        }
      } catch (error) {
        console.error("Error updating Firestore document: ", error);
      }
    },
  },
};
</script>

<style>
.emoji-container {
  display: flex;
}

.emoji-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20%;
  width: 100%;
}
</style>
