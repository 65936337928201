<template>
  <div class="expanded-section">
    <p>🔍 <strong>{{ displayName }} also recently looked at</strong></p>
    <ul>
      <li>brunch near me <span class="time">6m ago</span></li>
      <li>chelsea brunch <span class="time">12m ago</span></li>
    </ul>
    <p>👥 <strong>Others searched for</strong></p>
    <ul>
      <li>brunch nyc (3)</li>
      <li>brunch (12)</li>
    </ul>
    <a :href="url" class="search-results-link">🔗 View search results</a>
  </div>
</template>

<script>
export default {
  props: {
    postId: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  }
};
</script>

<style scoped>
.expanded-section {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: auto;
  margin: 10px 15px 10px 10px;
}

.expanded-section p {
  margin: 0 5px 5px 0;
  font-size: 14px;
  font-weight: bold;
}

.expanded-section ul {
  list-style: none;
  padding: 0;
  margin: 5px 0;
}

.expanded-section ul li {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  color: #333;
}

.expanded-section .time {
  color: gray;
  font-size: 12px;
}

.search-results-link {
  display: block;
  color: rgb(29, 155, 240);
  font-size: 13px;
  margin-top: 8px;
  text-decoration: none;
}

.search-results-link:hover {
  text-decoration: underline;
}
</style>