<template>
  <div id="emoji-item-total">
    <div class="emoji-item" @click="handleClick" @mouseover="isHovered = true" @mouseleave="isHovered = false">
      <div class="emoji-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15" :style="{
          backgroundColor: isHovered ? hoverColor : '',
        }">
          <path :d="svgPath" :fill="isClicked ? clickColor : 'none'"
            :stroke="isHovered || isClicked ? clickColor : defaultColor" fill-rule="evenodd"></path>
        </svg>
      </div>
    </div>
    <span :style="{
      color: isHovered ? clickColor : '',
    }">{{ count }}</span>
  </div>

</template>

<script>
export default {
  props: {
    svgPath: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    hoverColor: {
      type: String,
      required: true,
    },
    clickColor: {
      type: String,
      required: true,
    },
    voted: {
      type: Boolean,
      required: true,
    },
    emojiType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isHovered: false,
      isClicked: false,
      defaultColor: "rgb(83, 100, 113)"
    };
  },
  methods: {
    handleClick() {
      if (this.emojiType == 'seen') return;
      this.isClicked = !this.isClicked;
      this.$emit('toggleCount');
    },
  },
};
</script>

<style scoped>
#emoji-item-total {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  color: rgb(83, 100, 113);
}

svg {
  padding: 2px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}


.emoji-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #717070;
}

.emoji-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
}

.emoji-icon {
  display: flex;
  justify-content: center;
  align-items: center;

}

.emoji-icon:hover {}

.icon {}
</style>
