<template>
  <li class="directory-container">
    <div id="search-row">
      <img id="user-pfp" :src="pfpUrl">
      <div id="main-column">
        <div class="info-row">
          <span id="name"><b>{{ displayName }}</b></span>
          <span id="username">@{{ userHandle }}</span>
          <span class="time"> ∙ {{ formattedTime }}</span>
        </div>
        <a :href="url" class="linked-title">{{ title }}</a>
        <div id="image-result" v-if="imageFlag">
          <img :src="imageUrl" class="search-image" />
        </div>

        <div v-if="postId !== '0' && isVisible" id="search-react">
          <EmojiMenu :title="title" :postId="postId" @toggleExpanded="toggleExpandedSection" />
        </div>

      </div>


    </div>


    <div class="expanded-wrapper" v-if="expanded" :key="expanded">
      <ExpandedSection :expanded="expanded" :url="url" :displayName="displayName" :postId="postId" />
    </div>

  </li>
</template>

<script>
import EmojiMenu from './EmojiMenu.vue';
import ExpandedSection from './ExpandedSection.vue';
import { db } from '@/firebase';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';

export default {
  name: 'TweetListitem',
  components: {
    EmojiMenu,
    ExpandedSection,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    postId: {
      type: String,
    },
    time: {
      type: Number,
      required: true, // Visit time in milliseconds since epoch
    },
    imageFlag: {
      type: Boolean,
    },
    userHandle: {
      type: String,
      default: 'psychobath'
    },
    displayName: {
      type: String,
      default: 'Display Name'
    },
    pfpUrl: {
      type: String,
      default: '@/assets/bliss.webp'
    },
  },
  data() {
    return {
      imageUrl: null, // To store the fetched image URL
      retriesLeft: 3, //max is 3
      isVisible: false,  // Add this to track visibility
      observer: null,
      loggedView: false,
      expanded: false,
    };
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  beforeUnmount() {
    // Clean up the observer when component is destroyed
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  computed: {
    formattedTime() {
      const now = Date.now();
      const diffInMs = now - this.time;
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      if (diffInDays >= 1) {
        return `${diffInDays}d`;
      } else if (diffInHours >= 1) {
        return `${diffInHours}h`;
      } else {
        return `${diffInMinutes}m`;
      }
    },
  },
  methods: {
    setupIntersectionObserver() {
      this.observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            this.isVisible = true;

            console.log("a post is being looked at: ", this.title)
            // Only load image if this is a post that should have an image
            if (this.imageFlag) {
              this.loadImageUrl();
            }

            if (!this.loggedView) {
              this.tallyViewer(); // Prevent multiple updates
            }
            // Once we've started loading, we can disconnect the observer
            this.observer.disconnect();
          }
        },
        {
          rootMargin: '50px 0px', // Start loading when within 50px of viewport
          threshold: 0.1 // Trigger when even 10% of the element is visible
        }
      );

      this.observer.observe(this.$el);
    },
    async loadImageUrl() {
      console.log("i'm in loadImageurl now for: ", this.title);
      if (!this.isVisible) return; // don't do if not being looked at

      const postRef = doc(db, 'posts', this.postId);
      const docSnap = await getDoc(postRef);

      if (docSnap.exists() && docSnap.data().imageUrl) {
        this.imageUrl = docSnap.data().imageUrl;
        console.log("Loaded cached image:", this.imageUrl);

      } else {
        await this.fetchAndSaveImage(postRef);
      }
    },
    async fetchAndSaveImage(postRef) {

      try {
        const response = await fetch(`https://www.googleapis.com/customsearch/v1?q=${encodeURIComponent(this.title)}&cx=${process.env.VUE_APP_GOOGLE_CX}&key=${process.env.VUE_APP_GOOGLE_API_KEY}&searchType=image&num=1`);
        const data = await response.json();
        console.log("Full Google API response:", data);  // See what URLs you're getting
        const imageUrl = data.items?.[0]?.link;

        if (imageUrl) {
          this.imageUrl = imageUrl;
          await updateDoc(postRef, { imageUrl, updatedAt: new Date() });
          console.log("Fetched and saved image:", imageUrl);
        } else {

          throw new Error("Invalid image URL");
        }
      } catch (error) {
        console.log("Retrying fetch due to error:", error);
        await this.fetchAndSaveImage(postRef);
      }
    },
    async tallyViewer() {
      console.log("i'm in Tallyviewer now for: ", this.title);
      if (this.loggedView) return; // Prevent multiple calls

      console.log("tallyViewer() called for postId:", this.postId);

      try {
        const postRef = doc(db, 'posts', this.postId);
        await updateDoc(postRef, { "postReactions.seen": increment(1) });
        console.log("Successfully updated Firestore seen count.");
      } catch (error) {
        console.error("Error updating Firestore:", error);
      }
      this.loggedView = true;
    },
    toggleExpandedSection(state) {
      console.log("Received toggle event:", state);
      this.expanded = state;
    }
  }
}
</script>

<style scoped>
.directory-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.expanded-wrapper {
  width: 80%;
  align-self: flex-end;
}

.info-row {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  gap: 5px;
  font-family: 'Chirp';
  align-items: center;
}

#main-column {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}

#name {}

#username {
  color: grey
}

#search-react {
  width: 100%;
}

#search-row {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: horizontal;
  gap: 10px;
  width: 100%;
}

.linked-title {
  text-decoration: none;
  font-family: 'Chirp';
  color: rgb(29, 155, 240);
  margin-bottom: 2px;
  /* font-size: larger; */
}

.linked-title:hover {
  text-decoration: underline;
}

#user-pfp {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  align-items: center;
}

#search-icon {
  width: 10px;
  height: 10px
}

.time {
  color: rgb(152, 151, 151);
  font-size: 14px;
  margin-left: -5px;
}

#image-result {
  margin: 10px 0px;
}

.search-image {
  width: 80%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}
</style>