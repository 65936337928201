<template>
  <li class="directory-container">
    <div id="user-box" v-for="user in users" :key="user.userGID" @click="$emit('changePage', 'profile', user)">
      <img id="user-pfp" :src="user.pfpUrl">
      <div class="info-row">
        <span id="name"><b>{{ user.displayName }}</b></span>
        <span id="username">@{{ user.handle }}</span>
      </div>
    </div>
  </li>
</template>


<script>

export default {
  name: 'PeepleDirectory',
  components: {
  },
  props: {
    userHandle: {
      type: String,
      default: 'psychobath'
    },
    displayName: {
      type: String,
      default: 'Display Name'
    },
    pfpUrl: {
      type: String,
      default: '@/assets/bliss.webp'
    },
    users: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
};
</script>

<style scoped>
.directory-container {
  border-bottom: 0.5px solid #ececec;
  display: flex;
  flex-direction: column;
  width: 100%;
}

#user-box {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  gap: 5px;
  font-family: 'Chirp';
  padding: 15px 15px;
}

#user-box:hover {
  background-color: rgba(198, 197, 197, 0.1);
}

.info-row {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

#name {}

#username {
  color: grey
}

#search-react {
  width: 100%;
}

#search-row {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: horizontal;
  gap: 10px;
  width: 100%;
}

#user-pfp {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  align-items: center;
}
</style>
